<template>
	<div class="about">
		<h1>{{ levelData.name }}</h1>
		<div class="container p-0">
			<div class="row">
				<div class="col-sm">
					<canvas
						width="576px"
						height="448px"
						style="background-color: cadetblue;"
						id="game-canvas"
					></canvas>
				</div>
				<div class="col-sm" v-if="levelData.leveldata">
					<div class="row">
						<div class="col-sm">
							<level-view
								:levelData="levelData.leveldata"
								:levelName="levelData.name"
								:tileWidth="12"
							></level-view>
							<button class="btn btn-xs" @click="restartGame()">Restart</button>
						</div>
						<div class="col-sm text-left">
							Difficulty: {{ levelData.difficulty }}<br />
							Avg score: {{ score }} ({{ levelData.score_count }} times) <br />
							<br />
							<h5>Note:</h5>
							<p class="text-justify overflow-auto">
								<small>{{ levelData.note }}</small>
							</p>
						</div>
					</div>
				</div>
			</div>
			<chat-box v-if="levelId" :level-id="levelId"></chat-box>
		</div>
	</div>
</template>

<style lang="scss"></style>
<script>
import LevelView from '../components/LevelView.vue';
import ChatBox from '../components/ChatBox.vue';

export default {
	name: 'PlayLevel',
	mounted: async function() {
		let id = this.$route.params.id;
		this.levelId = +id;
		await this.loadLevel(id);
		this.runGame();
	},
	beforeDestroy: function() {
		if (this.gameInstance) {
			this.gameInstance.stop();
		}
	},
	components: {
		LevelView,
		ChatBox
	},
	data: function() {
		return {
			levelId: 0,
			levelData: {},
			gameInstance: void 0
		};
	},
	computed: {
		score() {
			if (!this.levelData.score_count) {
				return '-';
			}
			return new Number(
				this.levelData.score / this.levelData.score_count
			).toFixed(2);
		}
	},
	methods: {
		loadLevel(id) {
			return fetch('/api/v1/levels/' + id)
				.then(data => data.json())
				.then(data => {
					this.levelData = data.lvlData;
					console.log(data.lvlData);
				});
		},
		restartGame() {
			this.gameInstance.robboGame.killRobbo();
		},
		runGame() {
			let levelData = this.levelData.leveldata.split(',').map(v => +v);
			/* eslint-disable */
			this.gameInstance = new Game({
				containerId: "game-canvas", 
				width: 576,
				height: 448,
				rootFolder: "robbo/",
				testLevelData: levelData,
				debug: false,
				levels: [
					"./levels/levels",      // RobboLevels
					"./levels/levels2",     // RobboLevels2
					"./levels/cejsoft",     // CejsoftLevels
					"./levels/boss",        // BossLevels
					"./levels/653924",      // Levels653924
					"./levels/antic-soft",  // AnticSoftLevels
					"./levels/janusoft",    // JanusoftLevels
					"./levels/robbocop",    // RobbocopLevels
				]
			});
			/* eslint-enable */
		}
	}
};
</script>
